import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardResumoProdutosComponent } from './card-resumo-produtos/card-resumo-produtos.component';
import { CardBasicoModule } from '@vip/ui/card-basico';
import { CardResumoProdutosItemComponent } from './card-resumo-produtos-item/card-resumo-produtos-item.component';
import { ImageModule } from '@vip/ui/image';
import { CoreModule } from '@vip/core';
import { PropsProdutoSkeletonComponent } from './props-produto-skeleton/props-produto-skeleton.component';

@NgModule({
  imports: [
    CommonModule,
    CardBasicoModule,
    ImageModule,
    CoreModule,
    PropsProdutoSkeletonComponent,
  ],
  declarations: [CardResumoProdutosComponent, CardResumoProdutosItemComponent],
  exports: [CardResumoProdutosComponent, CardResumoProdutosItemComponent],
})
export class CardResumoProdutosModule {}
