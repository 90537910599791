import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BaseComponentSkeletonComponent,
  SkeletonModule,
} from '@vip/ui/skeleton';

@Component({
  selector: 'vip-props-produto-skeleton',
  imports: [CommonModule, SkeletonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './props-produto-skeleton.component.html',
  standalone: true,
})
export class PropsProdutoSkeletonComponent extends BaseComponentSkeletonComponent {}
