<vip-base-component-skeleton [quantity]="1" class="w-full">
  <ng-template #skeletonContent>
    <div class="rounded-lg p-4 shadow w-full">
      <div class="flex items-center gap-4">
        <div
          *vipSkeleton="true; height: '50px'; width: '50px'; radius: '5px'"
          class="mr-3"
        ></div>
        <div class="flex-1">
          <span
            *vipSkeleton="true; height: '16px'; width: '180px'"
            class="block mb-1"
          ></span>
          <div class="flex items-center mt-1">
            <span
              *vipSkeleton="true; height: '14px'; width: '70px'"
              class="line-through opacity-50"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</vip-base-component-skeleton>
