<ng-container *ngIf="!isLoading; else skeletonTemplate">
  <vip-card-basico
    [title]="title"
    icon="icon-shopping_basket"
    [button]="showButton ? 'Ver itens' : ''"
    [disableRipple]="!!produtos.length"
    [quantity]="produtos | qtdItensProdutos"
    (buttonClick)="verProdutosClick.emit()"
    (cardClick)="!produtos.length ? adicionarProdutosClick.emit() : null"
    [showHeaderDivider]="isDesktop"
    [isDesktop]="isDesktop"
    [titleSize]="isDesktop ? 'lg' : 'base'"
  >
    <div class="mt-3">
      <ng-container
        *ngFor="let produtoCompra of resumoProdutos; let last = last"
      >
        <vip-card-resumo-produtos-item
          [produtoCompra]="produtoCompra"
          (click)="itemProdutoClick.emit(produtoCompra)"
        ></vip-card-resumo-produtos-item>

        <hr
          *ngIf="!last || showButton"
          class="mt-2 w-full"
          [ngClass]="{ 'mb-2': !last }"
        />
      </ng-container>
      <div *ngIf="!produtos.length" class="text-xs vip-color-secondary-default">
        Adicionar produtos na lista
      </div>
    </div>
  </vip-card-basico>
</ng-container>

<ng-template #skeletonTemplate>
  <vip-props-produto-skeleton class="w-full"></vip-props-produto-skeleton>
</ng-template>
